import React from "react"

export default () => {
  return (
    <footer className="footer">
      <div className="color-bar">
        <p>Copyright © 2020 ayala All Rights Reserved. </p>
      </div>
    </footer>
  )
}
